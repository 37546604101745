<template>
  <div class="main gray-bg">
    <van-nav-bar class="top-bar has-bg-bar" title="帮助中心" left-arrow @click-left="onClickLeft"/>
    <div class="bar-bg-head">
      <div class="bar-bg-title">Hi~我是客服小二</div>
      <div class="bar-bg-text">遇到问题请莫慌，常见问题看下方！</div>
    </div>
    <div class="help-center-container">
      <div class="help-cell" v-for="(item,index) in list" :key="index">
        <div class="help-question-title" @click="getQuestionList(item)">
          <van-icon :name="item.icon"/>
          {{ item.type_name }}
        </div>
        <div class="help-question-list" v-if="item.question">
          <template v-for="(sub,key,index) in item.question" :key="key">
            <div class="van-ellipsis help-col" v-if="index < 2" @click="getQuestionList(item)">{{ sub.title }}</div>
          </template>
        </div>
      </div>
    </div>
    <div class="custom-box flex align-center justify-between">
      <van-button color="#FFA40C" plain @click="handleService">联系我</van-button>
    </div>

    <!--子列表弹窗-->
    <van-popup v-if="questionListVisible" :show="questionListVisible" class="max-popup">
      <Question :title="questionType" :list="questionList" @closePopup="closePopup"></Question>
    </van-popup>
    <!--子列表弹窗-->
  </div>
</template>

<script>
import {Button, Icon, NavBar, Popup, Tag} from "vant";

export default {
  name: "ToolHelpCenter",
  components: {
    [NavBar.name]: NavBar,
    [Tag.name]: Tag,
    [Popup.name]: Popup,
    [Icon.name]: Icon,
    [Button.name]: Button,
  }
}
</script>
<script setup>
import {onMounted, onUnmounted, reactive, ref} from "vue";
import {helpCenter} from "@/api/tool";
import "@/style/common.css"
// import {is_mobile} from "@/utils/devices";
import Question from "@/views/tool/Question";
import {exitPage} from "@/utils/callback";
import {asyncCopy, debugMeg, loadOutsideJS} from "@/utils/function";
import {encode} from "js-base64";
import {is_android_sdk, is_mobile, isLandscape} from "@/utils/devices";
import {userInfoToCustomer} from "@/api/user";
import {Toast} from "vant";

const list = ref([]);
const questionListVisible = ref(false)
const questionType = ref('')
const questionList = ref({})

const serviceConfig = reactive({
  host: process.env.VUE_APP_SERVICE_HOST,
  appId: process.env.VUE_APP_SERVICE_APP_ID,
  params: {
    uid: "",//项目名+'_'+环境+用户id
    username: "",//用户账号
    userRoleName: "",//用户账号
    userRoleBalance: "0",//历史充值金额
    userRoleParty: "",//角色
    userRoleServer: "-",//渠道归属
    extend: "",
    width: '800px',
    height: '700px',
  }
})
//返回
const onClickLeft = () => {
  exitPage()
};
onMounted(() => {
  //初始化数据
  helpCenter().then(({data}) => {
    list.value = data.question_data
  })
  loadOutsideJS(`${serviceConfig.host}/static/app/libQuickService.js`)

  window.addEventListener('resize', setServiceConfig);
  window.addEventListener('message', listenerService);
})
onUnmounted(()=>{
  window.removeEventListener('resize', setServiceConfig);
  window.removeEventListener('message', listenerService);
})

//获取子列表
const getQuestionList = (item) => {
  questionListVisible.value = true
  questionType.value = item.type_name
  questionList.value = item.question
}
//关闭弹窗
const closePopup = () => {
  questionListVisible.value = false
}
// 配置客服
function setServiceConfig() {
  serviceConfig.params.width = document.body.clientWidth < 1080 ? (is_mobile() && isLandscape() ? `${document.body.clientHeight}px` : `${document.body.clientWidth}px`) : '800px';
  serviceConfig.params.height = document.body.clientHeight < 810 ? (is_mobile() && isLandscape() ? `${document.body.clientWidth}px` : `${document.body.clientHeight}px`) : '700px';
  if ((document.body.clientWidth < 1080 || document.body.clientHeight < 810) && (is_mobile() && isLandscape())) {
    document.body.classList.add("landscape");
  }
}
// 拉起客服
const handleService = () => {
  setServiceConfig()
  userInfoToCustomer().then(({data}) => {
    serviceConfig.params.uid = `yxy_${process.env.VUE_APP_ENV}` + data.user_id
    serviceConfig.params.username = data.user_account
    serviceConfig.params.userRoleName = data.user_account
    serviceConfig.params.userRoleBalance = data.recharge_amount
    serviceConfig.params.userRoleParty = data.role_name || '-'
    let extendObjet = [
      {
        title: '游戏区服',
        value: data.server_name || '-',
        key: 'server_name',
        chat_title: true
      }, {
        title: '最近游戏',
        value: data.game_name || '-',
        key: 'game_name',
        chat_title: true
      }
    ];
    serviceConfig.params.extend = encode(JSON.stringify(extendObjet))
    // eslint-disable-next-line no-undef
    QuickService.setHost(serviceConfig.host).setAppId(serviceConfig.appId).setParams(serviceConfig.params).show();
  })
}


const listenerService = (e) => {
  debugMeg(e.data)
  if(e.data.action){
    switch (e.data.action) {
      case "copyText":
        asyncCopy(e.data.data.text)
        break;
      case "openUrl":
        if(!e.data.data.data) return false
        if(is_android_sdk()){
          try {
            window.and_youxiyou.OpenWebviewToBrowser(e.data.data.data)
          }catch (e){
            Toast("打开失败，请复制到浏览器打开")
          }
        }else{
        top.open(e.data.data.data)
        }
        break;
    }

  }
}
</script>

<style scoped>
.top-bar {
  z-index: 999;
}

.top-bar, .top-bar :deep(.van-nav-bar__content) {
  background: none;
}

.bar-bg-head {
  position: absolute;
  z-index: 5;
  width: 100%;
  top: 0px;
  color: #fff;
  padding: 44px 16px 57px 120px;
  height: 171px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-flow: column;
  text-align: left;
  background: url("../../assets/images/tool/help-head.png") bottom center no-repeat;
  background-size: 100% auto;
}

.bar-bg-title {
  font-size: 18px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 21px;
}

.bar-bg-text {
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 21px;
  margin-top: 4px;
}

.help-center-container {
  background: #FFFFFF;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin: 0px 16px;
  position: relative;
  z-index: 8;
  margin-top: 70px;
  text-align: left;
  color: #4E5969;
  padding: 10px;
}

.help-question-title {
  font-size: 16px;
  font-weight: 500;
  color: #4E5969;
  line-height: 22px;
  display: flex;
  align-items: center;
  padding: 6px;
}

.help-question-title .van-icon {
  margin-right: 5px;
}

.help-question-list {
  padding: 6px 0px;
  font-size: 14px;
  font-weight: 400;
  color: #4E5969;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.help-col {
  padding: 0px 6px;
  width: 50%;
}

.custom-box {
  background: #FFFFFF;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin: 12px 16px;
  padding: 6px;
}

.custom-box .van-button {
  width: 100%;
  height: 32px;
}
</style>
